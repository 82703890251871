import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className="header">
        <img src={Logo} alt="" className='logo'/>
        <ul className='header-menu'>
            <li>Home</li>
            <li><a href="#st">Why Us</a></li>
            <li><a href="#programs">Programs</a></li>
            <li> <a href="#plans">Plans</a></li>
            <li><a href="#testimonials">Testimonials</a></li>
        </ul>


    </div>
  )
}

export default Header
